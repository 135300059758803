<template>
  <div class="columns is-align-items-flex-end is-multiline">
    <div class="column">
      <p class="is-size-7 has-text-weight-light">
        {{ getDate(event.date) }}
      </p>
      <span class="title is-size-5 has-text-dark">Numberplate change</span>
    </div>
    <div class="column is-narrow">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <button
            class="button is-dark has-text-dark has-background-white is-outlined is-disabled"
          >
            {{ event.previousVRM }}
          </button>
        </div>
        <div class="column is-narrow">
          <span class="icon has-text-info">
            <i class="fal fa-arrow-right" />
          </span>
        </div>
        <div class="column is-narrow">
          <button
            class="button is-dark has-text-dark has-background-white is-outlined is-disabled"
          >
            {{ event.currentVRM }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'TimelinePlate',
  props: {
    event: {
      type: Object,
      required: true
    },
    vrm: {
      type: String,
      required: true
    }
  },
  methods: {
    getDate(value) {
      return formatDate(value, 'do MMMM yyyy')
    }
  }
}
</script>
